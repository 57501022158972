<template>
    <div>
      <button class="m-5 btn btn-dager text-white" data-bs-target="#modal1">Open Modal</button>
  
      <!-- Bootstrap Modal -->
      <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Tabs in Modal</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <ul class="nav nav-tabs" id="myTabs" role="tablist">
                <li class="nav-item">
                  <a class="nav-link" :class="{ active: activeTab === 0 }" id="tab1" data-bs-toggle="tab" href="#content1" role="tab" @click="changeTab(0)">Tab 1</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{ active: activeTab === 1 }" id="tab2" data-bs-toggle="tab" href="#content2" role="tab" @click="changeTab(1)">Tab 2</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{ active: activeTab === 2 }" id="tab3" data-bs-toggle="tab" href="#content3" role="tab" @click="changeTab(2)">Tab 3</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{ active: activeTab === 3 }" id="tab4" data-bs-toggle="tab" href="#content4" role="tab" @click="changeTab(3)">Tab 4</a>
                </li>
              </ul>
              <div class="tab-content" id="myTabsContent">
                <div class="tab-pane" :class="{ active: activeTab === 0 }" id="content1" role="tabpanel">Content for Tab 1 goes here.</div>
                <div class="tab-pane" :class="{ active: activeTab === 1 }" id="content2" role="tabpanel">Content for Tab 2 goes here.</div>
                <div class="tab-pane" :class="{ active: activeTab === 2 }" id="content3" role="tabpanel">Content for Tab 3 goes here.</div>
                <div class="tab-pane" :class="{ active: activeTab === 3 }" id="content4" role="tabpanel">Content for Tab 4 goes here.</div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        activeTab: 0
      };
    },
    methods: {
    
      changeTab(index) {
        this.activeTab = index;
      }
    }
  };
  </script>
  
  <style scoped>
  .active {
    background-color: blue; /* Add your active styles here */
    color: white;
  }
  </style>
  